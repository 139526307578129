/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 *
 * @author albin
 */
import $ from 'jquery';
import { csrf } from './security';

function sendRequest(options) {
  let hasDataObject = false;

  if (options.fileUpload && /put|post|patch/i.test(options.type)) {
    options.processData = false;
    options.contentType = false;
  } else if (!options.data || typeof options.data === 'object') {
    hasDataObject = true;
    options.data = options.data || {};
    options.data.svAjaxReqParam = 'ajax'; // SV-23492 - https://stackoverflow.com/a/16152893/1397073
  }

  if (
    !/get/i.test(options.type) &&
    hasDataObject &&
    options.processData !== false
  ) {
    options.processData = false;
    options.data = JSON.stringify(options.data);
  }

  // This is for clarity, the header will be added globally in "beforeSend" as well
  if (/put|post|patch|delete/i.test(options.type)) {
    const csrfToken = csrf.getToken();

    if (csrfToken) {
      options.headers = options.headers || {};
      options.headers[csrf.getHeaderName()] = csrfToken;
    }
  }

  return $.ajax(options);
}

const BASE_OPTIONS = {
  type: 'get',
  dataType: 'json',
};
const JSON_OPTIONS = Object.assign(
  {},
  {
    contentType: 'application/json; charset=utf-8',
  },
  BASE_OPTIONS,
);
const DEFAULT_OPTIONS = {
  get: Object.assign({}, BASE_OPTIONS),
  put: Object.assign({}, JSON_OPTIONS, { type: 'put' }),
  post: Object.assign({}, JSON_OPTIONS, { type: 'post' }),
  delete: Object.assign({}, JSON_OPTIONS, { type: 'delete' }),
  patch: Object.assign({}, JSON_OPTIONS, { type: 'patch' }),
};

export const doGet = function (options) {
  return sendRequest(Object.assign({}, DEFAULT_OPTIONS.get, options));
};

export const doPut = function (options) {
  return sendRequest(Object.assign({}, DEFAULT_OPTIONS.put, options));
};

export const doPost = function (options) {
  return sendRequest(Object.assign({}, DEFAULT_OPTIONS.post, options));
};

export const doDelete = function (options) {
  return sendRequest(Object.assign({}, DEFAULT_OPTIONS['delete'], options));
};

export const doPatch = function (options) {
  return sendRequest(Object.assign({}, DEFAULT_OPTIONS.patch, options));
};
