/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 *
 * @author albin
 */
import $ from 'jquery';
import events from 'events';
import Class from 'class.extend';

const App = Class.extend(
  Object.assign(
    {
      init: function (context) {
        this.$el = $('#' + context.htmlElementId);
        this.portletId = context.portletId;
        this.route = context.route;
        this.locale = context.locale;
        this.defaultLocale = context.defaultLocale;
        this.webAppId = context.webAppId;
        this.webAppVersion = context.webAppVersion;
        this.webAppAopId = context.webAppAopId;
        this.webAppImportTime = context.webAppImportTime;
        this.childComponentStateExtractionStrategy =
          context.childComponentStateExtractionStrategy;
      },
    },
    events,
  ),
);

export default App;
