/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 *
 * @author albin
 */

export const csrf = {
  getToken: function () {
    var token = window.sv.PageContext.csrfToken;

    return token || null;
  },
  getParameterName: function () {
    return 'sv.csrfToken';
  },
  getHeaderName: function () {
    return 'X-CSRF-Token';
  },
};
