/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 *
 * @author albin
 */

import Class from 'class.extend';

function getLanguageFromLocale(locale) {
  const localeParts = locale.split('-');

  return localeParts[0];
}

function populateBundleWithDefaults(locale, defaultLocale, bundles) {
  const bundle = Object.assign({}, bundles[defaultLocale]);

  if (locale === defaultLocale) {
    return bundle;
  }

  let candidate;
  const appLanguage = getLanguageFromLocale(locale).toLowerCase();

  Object.keys(bundles).find(function (key) {
    const language = getLanguageFromLocale(key).toLowerCase();

    if (appLanguage === language) {
      candidate = bundles[key];

      if (locale === key) {
        return true;
      }
    }
  });

  if (candidate) {
    Object.assign(bundle, candidate);
  }

  return bundle;
}

const i18n = Class.extend({
  init: function (locale, defaultLocale, bundles) {
         this.defaultLocale = defaultLocale;
         this.bundles = bundles;
    this.bundle = populateBundleWithDefaults(locale, defaultLocale, bundles);
  },

  get: function (key) {
    if (arguments.length === 1) {
      return this.bundle[key] || key;
    }

    const args = Array.prototype.slice.call(arguments, 1);
    let res = this.bundle[key];

    for (var i = 0; i < args.length; i++) {
      var reg = new RegExp('\\{' + i + '\\}', 'gm');

      res = res.replace(reg, args[i]);
    }

    return res || key;
  },

  forLocale: function(locale) {
    if (locale) {
      return new this.constructor(locale, this.defaultLocale, this.bundles);
    }
  }
});

export default i18n;
