/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 *
 * @author albin
 */
import $ from 'jquery';
import _ from 'underscore';
import Class from 'class.extend';
import events from 'events';

const EVENT_SPLITTER = /^(\S+)\s*(.*)$/;

const generateUUID = (() => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  return () => `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
})();

function iterateDomEvents(events, callback, context) {
  if (!events) {
    return;
  }

  Object.entries(events).forEach(function ([event, handler]) {
    var match = event.match(EVENT_SPLITTER);

    callback.call(context, match[1], match[2], handler);
  });
}

function bindObjectEvents(events, emitter, context) {
  if (!events) {
    return;
  }

  Object.entries(events).forEach(function ([event, callback]) {
    context.listenTo(emitter, event, context[callback]);
  });
}

function unbindObjectEvents(events, emitter, context) {
  if (!events) {
    return;
  }

  Object.entries(events).forEach(function ([event, callback]) {
    context.stopListening(emitter, event, context[callback]);
  });
}

function bindInternalEvents(events, context) {
  if (!events) {
    return;
  }

  Object.entries(events).forEach(function ([event, callback]) {
    context.on(event, context[callback]);
  });
}

function bindStoreSubscription(callback, store, context) {
  if (!callback) {
    return;
  }

  context._unsubscribe = store.subscribe(() => {
    var newState = context.filterState(store.getState(), context.options);

    if (!Object.is(context.state, newState)) {
      context[callback](newState);
    }
  });
}

function unbindStoreSubscription(context) {
  context._unsubscribe && context._unsubscribe();
}

function unbindInternalEvents(events, context) {
  if (!events) {
    return;
  }

  Object.entries(events).forEach(function ([event, callback]) {
    context.off(event, context[callback]);
  });
}

function unbindEventsRecursively(subComponents) {
  subComponents.forEach(function (subComponent) {
    subComponent._unbindEvents();

    if (subComponent._subComponents && subComponent._subComponents.length) {
      unbindEventsRecursively(subComponent._subComponents);
    }
  });
}

var triggerMethod = (function () {
  // split the event name on the ":"
  var splitter = /(^|:)(\w)/gi;

  // take the event section ("section1:section2:section3")
  // and turn it in to uppercase name
  function getEventName(match, prefix, eventName) {
    return eventName.toUpperCase();
  }

  return function (context, event, args) {
    var noEventArg = arguments.length < 3;

    if (noEventArg) {
      args = event;
      event = args[0];
    }

    // get the method name from the event name
    var methodName = 'on' + event.replace(splitter, getEventName);
    var method = context[methodName];
    var result;

    // call the onMethodName if it exists
    if (typeof method === 'function') {
      // pass all args, except the event name
      result = method.apply(context, noEventArg ? _.rest(args) : args);
    }

    // trigger the event, if a trigger method exists
    if (typeof context.trigger === 'function') {
      if (noEventArg + args.length > 1) {
        context.trigger.apply(
          context,
          noEventArg ? args : [event].concat(_.drop(args, 0)),
        );
      } else {
        context.trigger(event);
      }
    }

    return result;
  };
})();

export const create = function (
  context,
  require,
  app,
  router,
  i18n,
  globalEvents,
  store,
) {
  return Class.extend(
    Object.assign(
      {
        tagName: 'div',

        isRendered: false,

        init: function (state, options) {
          var serverRendered;

          if (options) {
            this.$el = options.$el;
            this.cid = options.cid;
            serverRendered = options.serverRendered;
            this.options = options.options;
          } else {
            options = {};
          }

          if (!this.cid) {
            this.cid = generateUUID();
          }

          this._bindEvents();
          this.state = state;
          this._subComponents = [];

          this.listenToOnce(app, 'components:loaded', function (components) {
            var subComponents = options.subComponents;

            if (!subComponents) {
              return;
            }

            this._subComponents = subComponents.map(function (componentId) {
              return components[componentId];
            });
          });

          this.on('attached', this._bindDOMEvents);
          this.triggerMethod('init');

          if (serverRendered) {
            var eventOptions = { serverRendered: serverRendered };

            this.triggerMethod('rendered', eventOptions);
            this.triggerMethod('attached', eventOptions);
          }
        },

        // Overridden by Components to filter a state from the WebApp's global state (store)
        filterState: function () {
          return {};
        },

        $: function (selector) {
          return this.$el.find(selector);
        },

        _bindEvents: function () {
          if (!this.events) {
            return;
          }

          bindObjectEvents(this.events.app, app, this);
          bindObjectEvents(this.events.router, router, this);
          bindObjectEvents(this.events.global, globalEvents, this);
          bindStoreSubscription(this.events.store, store, this);
          bindInternalEvents(this.events.self, this);
        },

        _bindDOMEvents: function () {
          if (!this.events) {
            return;
          }

          iterateDomEvents(
            this.events.dom,
            function (event, selector, handler) {
              this.$el
                .off(event, selector, this[handler])
                .on(event, selector, $.proxy(this[handler], this));
            },
            this,
          );
        },

        _unbindEvents: function () {
          if (!this.events) {
            return;
          }

          unbindObjectEvents(this.events.app, app, this);
          unbindObjectEvents(this.events.router, router, this);
          unbindInternalEvents(this.events.self, this);
          unbindStoreSubscription(this);
          iterateDomEvents(
            this.events.dom,
            function (event, selector, handler) {
              this.$el.off(event, selector, this[handler]);
            },
            this,
          );
        },

        _templateFunctions: function () {
          var renderTemplate = this.renderTemplate.bind(this),
            _this = this;

          return {
            renderer: {
              renderPartial: function (path, options) {
                var template = require(path);

                return renderTemplate(template, options);
              },
              renderComponent: (componentName, options) => {
                var Component = require('/component/' + componentName),
                  state = Component.prototype.filterState(
                    store.getState(),
                    options,
                  ),
                  component = new Component(state, {
                    options: options,
                  });

                _this._subComponents.push(component);
                _this.renderedSubComponents[component.cid] = component;

                var tagName = _.escape(_.result(component, 'tagName'));

                return (
                  '<' +
                  tagName +
                  ' data-cid="' +
                  component.cid +
                  '"></' +
                  tagName +
                  '>'
                );
              },
            },

            getUrl: router.getUrl.bind(router),

            getStandaloneUrl: router.getStandaloneUrl.bind(router),

            getResourceUrl: function (path) {
              if (path.charAt(0) === '/') {
                path = path.substring(1);
              }
              var pageId = window.sv.PageContext.pageId;

              return (
                '/webapp-files/' +
                pageId +
                '/' +
                app.webAppAopId +
                '/' +
                app.webAppImportTime +
                '/' +
                path
              );
            },

            i18n: i18n,

            appContext: {
              getWebAppNamespace: function (prefix) {
                return prefix + context.portletId.replace('.', '_');
              },
            },
          };
        },

        _ensureEl: function () {
          if (!this.$el) {
            this.$el = $('<' + _.escape(_.result(this, 'tagName')) + '/>').data(
              'cid',
              this.cid,
            );
          }
        },

        getTemplate: function () {
          return this.template;
        },

        renderTemplate: function (template, options) {
          return template(
            Object.assign(
              this._templateFunctions(),
              _.result(this, 'templateFunctions'),
              options,
            ),
          );
        },

        render: function () {
          var attributes, className;

          this.renderedSubComponents = {};
          unbindEventsRecursively(this._subComponents);
          this._subComponents = [];
          this._ensureEl();

          className = _.escape(_.result(this, 'className'));
          if (className) {
            this.$el.addClass(className);
          }

          attributes = _.result(this, 'attributes');
          if (attributes) {
            this.$el.attr(attributes);
          }

          this.$el.html(this.renderTemplate(this.getTemplate(), this.state));
          this.isRendered = true;

          this.triggerMethod('rendered');

          Object.entries(this.renderedSubComponents).forEach(function ([
            cid,
            component,
          ]) {
            this.$el
              .find('[data-cid="' + cid + '"]')
              .replaceWith(component.render().$el);
          }, this);

          this.triggerMethod('attached');

          return this;
        },

        destroy: function () {
          this.triggerMethod('destroy');
          this._unbindEvents();
          unbindEventsRecursively(this._subComponents);

          this.$el && this.$el.remove();
        },

        setState: function (key, value, options) {
          var attrs,
            changed = {};

          if (typeof key === 'object') {
            attrs = key;
            options = value;
          } else {
            (attrs = {})[key] = value;
          }

          options || (options = {});

          Object.entries(attrs).forEach(function ([key, value]) {
            var oldValue = this.state[key];

            if (oldValue !== value) {
              this.state[key] = value;
              changed[key] = value;
            }
          }, this);

          Object.entries(changed).forEach(function ([key, value]) {
            this.triggerMethod('state:changed:' + key, value, options);
          }, this);

          this.triggerMethod('state:changed', changed, options);
        },

        triggerMethod: function () {
          triggerMethod(this, arguments);
        },
      },
      events,
    ),
  );
};
