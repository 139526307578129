/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 *
 * @author eric
 */

const i18n = window.sv.i18n;

const BASE_OPTIONS = {
      type: '',
      alertRole: true
};

const toasts = (function () {
  const defaults = {
    notifier: {
      delay: 4,
      showCheckmark: false,
      classes: {
        base: 'sv-toast__container',
        prefix: 'env-toast--',
        toast: 'env-toast',
        bottomLeft: 'sv-bottom-left',
        visible: 'env-toast--visible',
        hidden: 'env-toast--hide',
        close: 'env-toast__close',
      },
    },
  };

  const closeButtonTemplate = `<svg data-dismiss="close" class="env-icon env-icon--small">
         <use data-dismiss="close" xlink:href="/sitevision/envision-icons.svg#icon-delete"></use>
      </svg>`;

  const iconCheckDoneTemplate = `<div class="sv-toast-checkmark env-m-left--medium"><svg class="env-icon env-icon--small">
            <use xlink:href="/sitevision/envision-icons.svg#icon-check-done"></use>
         </svg></div>`;

  let element;
  const openInstances = [];
  const classes = defaults.notifier.classes;
  const baseClass = classes.base;

  function clearContents(element) {
    while (element.lastChild) {
      element.removeChild(element.lastChild);
    }
  }

  const transition = (function () {
    let t;
    let type;
    let supported = false;
    const transitions = {
      animation: 'animationend',
      OAnimation: 'oAnimationEnd oanimationend',
      msAnimation: 'MSAnimationEnd',
      MozAnimation: 'animationend',
      WebkitAnimation: 'webkitAnimationEnd',
    };

    for (t in transitions) {
      if (document.documentElement.style[t] !== undefined) {
        type = transitions[t];
        supported = true;
        break;
      }
    }

    return {
      type: type,
      supported: supported,
    };
  })();

  function initialize(instance) {
    if (!instance.__internal) {
      instance.__internal = {
        delay: defaults.notifier.delay,
      };

      element = document.createElement('DIV');
      updatePosition();
    }

    //add to DOM tree.
    if (element.parentNode !== document.body) {
      document.body.appendChild(element);
    }
  }

  function pushInstance(instance) {
    instance.__internal.pushed = true;
    openInstances.push(instance);
  }

  function popInstance(instance) {
    openInstances.splice(openInstances.indexOf(instance), 1);
    instance.__internal.pushed = false;
  }

  function updatePosition() {
    element.className = baseClass;
         element.classList.add(classes.bottomLeft);
  }

  function create(div, callback) {
    function clickDelegate(event, instance) {
      if (event.target.getAttribute('data-dismiss') === 'close') {
        instance.dismiss(true);
      }
    }

    function transitionDone(event, instance) {
      // unbind event
            instance.element.removeEventListener(transition.type, transitionDone);
      // remove the message
      element.removeChild(instance.element);
    }

    function delegate(context, method) {
      return function () {
        if (arguments.length > 0) {
          const args = [];

          for (let x = 0; x < arguments.length; x += 1) {
            args.push(arguments[x]);
          }
          args.push(context);

          return method.apply(context, args);
        }

        return method.apply(context, [null, context]);
      };
    }

    function initialize(instance) {
      if (!instance.__internal) {
        instance.__internal = {
          pushed: false,
          delay: undefined,
          timer: undefined,
          clickHandler: undefined,
          transitionEndHandler: undefined,
          transitionTimeout: undefined,
          showCheckmark: undefined,
        };
        instance.__internal.clickHandler = delegate(instance, clickDelegate);
        instance.__internal.transitionEndHandler = delegate(
          instance,
          transitionDone,
        );
      }

      return instance;
    }

    function clearTimers(instance) {
      clearTimeout(instance.__internal.timer);
      clearTimeout(instance.__internal.transitionTimeout);
    }

    function reflow(element) {
      if (element === undefined) {
        element = document.documentElement;
      }
      void element.offsetHeight;
    }

    return initialize({
      element: div,

            push: function (_content, _ttl, useCheckmark, alertRole) {
        if (!this.__internal.pushed) {
          pushInstance(this);
          clearTimers(this);

          let content;
          let wait;

          switch (arguments.length) {
            case 0:
              wait = this.__internal.delay;
              break;
            case 1:
              if (typeof _content === 'number') {
                wait = _content;
              } else {
                content = _content;
                wait = this.__internal.delay;
              }
              break;
            case 2:
              content = _content;
              wait = _ttl;
              break;
            case 3:
              content = _content;
              wait = _ttl;
              this.__internal.showCheckmark = useCheckmark;
                        break;
                     case 4:
                        content = _content;
                        wait = _ttl;
                        this.__internal.showCheckmark = useCheckmark;
                        alertRole;
                        break;
          }

          // set contents
          if (typeof content !== 'undefined') {
                     this.setContent(content, alertRole);
          }

          element.appendChild(this.element);

          reflow(this.element);
                  this.element.classList.add(classes.visible);
          // attach click event
                  this.element.addEventListener('click', this.__internal.clickHandler);

          return this.delay(wait);
        }

        return this;
      },

      ondismiss: function () {},

      callback: callback,

      dismiss: function (clicked) {
        if (this.__internal.pushed) {
          clearTimers(this);
          if (
            !(
              typeof this.ondismiss === 'function' &&
              this.ondismiss.call(this) === false
            )
          ) {
            //detach click event
                     this.element.removeEventListener('click', this.__internal.clickHandler);
            // ensure element exists
            if (
              typeof this.element !== 'undefined' &&
              this.element.parentNode === element
            ) {
              //transition end or fallback
              this.__internal.transitionTimeout = setTimeout(
                this.__internal.transitionEndHandler,
                transition.supported ? 1000 : 100,
              );

                        this.element.classList.add(classes.hidden);

              // custom callback on dismiss
              if (typeof this.callback === 'function') {
                this.callback.call(this, clicked);
              }
            }

            popInstance(this);
          }
        }

        return this;
      },

      delay: function (wait) {
        clearTimers(this);
        this.__internal.delay =
          typeof wait !== 'undefined' && !isNaN(+wait)
            ? +wait
            : toasts.__internal.delay;
        if (this.__internal.delay > 0) {
          const self = this;

          this.__internal.timer = setTimeout(function () {
            self.dismiss();
          }, this.__internal.delay * 1000);
        }

        return this;
      },

            setContent: function (content, alertRole) {
               const toastDiv = document.createElement('div');

               toastDiv.classList.add('env-toast__content');
               if (alertRole) {
                  toastDiv.setAttribute('role', 'alert');
               }

        if (typeof content === 'string') {
          clearContents(this.element);
          toastDiv.innerHTML = content;
        } else if (
          content instanceof window.HTMLElement &&
          toastDiv.firstChild !== content
        ) {
          clearContents(this.element);
                  toastDiv.appendChild(content);
        }

               this.element.appendChild(toastDiv);

        if (this.__internal.showCheckmark) {
          this.element.insertAdjacentHTML('afterbegin', iconCheckDoneTemplate);
        }

        const closeBtn = document.createElement('button');

               closeBtn.classList.add(classes.close);
        closeBtn.insertAdjacentHTML('beforeend', closeButtonTemplate);
        closeBtn.setAttribute('aria-label', i18n.getText('common', 'close'));
        closeBtn.setAttribute('data-dismiss', 'close');

        this.element.appendChild(closeBtn);

        return this;
      },
    });
  }

  return {
    create: function (type, callback) {
      //ensure notifier init
      initialize(this);
      //create new toast message
            const toastContainer = document.createElement('div');

      toastContainer.className =
        classes.toast +
        (typeof type === 'string' && type !== ''
          ? ' ' + classes.prefix + type
          : '');

      return create(toastContainer, callback);
    },
  };
})();

function transformMessage(heading, message) {
  return '<strong>' + heading + '</strong>' + ' ' + message;
}

document.addEventListener('sv-publish-toast', (event) => {
  const toast = event.detail;

  if (toast.heading) {
    toast.message = transformMessage(toast.heading, toast.message);
  }

  toasts
    .create(toast.type, toast.callback)
    .push(toast.message, toast.ttl, toast.checkmark, toast.alertRole);
});
   /**
     *  Valid option properties
     *  "type" : success, primary or ''
     *  "ttl" : (time to live) in seconds for toast to be shown
     *  "callback" : a callback function executed when toast is removed
     *  "checkmark" : boolean value if checkmark should be shown
     *  "heading" : a header string, (not required), will be <strong>
     *  "message" : a message string
     *  "alertRole"   : boolean value if role is alert or toast is decorative
     */
export const publish = (options) => {
  try {
    const event = new CustomEvent('sv-publish-toast', {
      detail: Object.assign({}, BASE_OPTIONS, options),
    });

    document.dispatchEvent(event);
  } catch (e) {
    window.console.log('Something went wrong! Failed to create Toast!');
  }
};