/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 *
 * @author albin
 */
import $ from 'jquery';
import _ from 'underscore';

function unbindEventsRecursively(subComponents) {
  subComponents.forEach(function (subComponent) {
    subComponent._unbindEvents();

    if (subComponent._subComponents && subComponent._subComponents.length) {
      unbindEventsRecursively(subComponent._subComponents);
    }
  });
}

export const create = function (Component, require, store) {
  return Component.extend({
    addItem: function (item, options) {
      const childComponent = this._createChildComponent(
        item,
        options && options.options,
      );

      this.$el[options && options.prepend ? 'prepend' : 'append'](
        childComponent.render().$el,
      );
    },

    _createChildComponent: function (state, options) {
      if (!this.childComponentPath) {
        throw new Error('No child component specified');
      }

      if (!this.ChildComponent) {
        this.ChildComponent = require('/component/' + this.childComponentPath);
      }

      options = Object.assign({}, options, { id: state.id });
      state = Object.assign(
        state,
        this.ChildComponent.prototype.filterState(store.getState(), options),
      );

      const childComponent = new this.ChildComponent(state, {
        options: options,
      });

      this._subComponents.push(childComponent);

      return childComponent;
    },

    triggerChildren: function (e, data) {
      this._subComponents.forEach(function (subComponent) {
        subComponent.trigger(e, data);
      });
    },

    getChildAt: function (index) {
      return this._subComponents[index];
    },

    getChildById: function (id) {
      return this._subComponents.find(function (child) {
        return child.state.id === id;
      });
    },

    removeChildById: function (id) {
      const childToRemove = this.getChildById(id);

      if (childToRemove) {
        this._subComponents = this._subComponents.filter(
          (child) => child !== childToRemove,
        );
        childToRemove.destroy();
      }
    },

    render: function () {
      const $temp = $('<div></div>');

      const className = _.result(this, 'className');
      const attributes = _.result(this, 'attributes');
      const childOptions = _.result(this, 'childOptions', {});

      unbindEventsRecursively(this._subComponents);
      this._subComponents = [];
      this._ensureEl();

      this.$el.removeClass();
      if (className) {
        this.$el.addClass(className);
      }

      if (attributes) {
        this.$el.attr(attributes);
      }

      this.state[this.childProperty].forEach(function (child) {
        $temp.append(
          this._createChildComponent(child, childOptions).render().$el,
        );
      }, this);

      this.$el.html('').append($temp.children());

      this.isRendered = true;
      this.triggerMethod('rendered');
      this.triggerMethod('attached');

      return this;
    },
  });
};
