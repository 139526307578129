/**
 * Copyright (C) SiteVision AB 2002-2017, all rights reserved
 *
 * @author albin
 */
define(function() {
   'use strict';

   return window.$svjq;
});
