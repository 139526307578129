/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 *
 * @author albin
 */

import WebApp from 'WebApp';

const AppRegistry = window.AppRegistry;

const AppStarter = {
  start: function (registry) {
    registry = registry || AppRegistry.getRegistry();
    AppRegistry.setAppStarter(this);

    Object.values(registry).forEach(function (application) {
      application.instances.forEach(function (context) {
        const instance = new WebApp(
          context,
          application.bundle,
          AppRegistry.getBootstrapData(context.portletId)
        );

        Object.entries(application.modules).forEach(function ([path, module]) {
          module.module(instance.createDefine(path));
        });

        instance.start();
      });
    });
  },
};

export default AppStarter;
